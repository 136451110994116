body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #aaa;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #999;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:active {
    background: #888;
}

/*#react-modal-image-img {*/
/*    height: calc(100% - 50px);*/
/*    max-height: calc(95% - 50px);*/
/*}*/
.__react_modal_image__modal_content {
    height: calc(100% - 50px) !important;
    max-height: calc(100% - 50px);
    bottom: 0;
    top: 50px;
}